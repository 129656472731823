import React, { useState } from 'react';
import './App.css';

function App() {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [existingSchema, setExistingSchema] = useState(null);
  const [suggestedSchema, setSuggestedSchema] = useState(null);

  // Handle form submit
  const handleAnalyze = async () => {
    if (!url) return;

    setLoading(true);
    setExistingSchema(null);
    setSuggestedSchema(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_SERVER}/api/page-scan/scan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url }),
      });

      const {data} = await response.json();
      console.log(data)
      setExistingSchema(data.scan_summary.existing_schema);
      
      setSuggestedSchema(data.scan_summary.suggested_schema);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Copy suggested schema to clipboard
  const handleCopy = () => {
    if (suggestedSchema) {
      navigator.clipboard.writeText(JSON.stringify(suggestedSchema, null, 2))
        .then(() => alert('Suggested schema copied to clipboard!'))
        .catch(err => console.error('Failed to copy text: ', err));
    }
  };

  return (
    <div className="app">
      <aside className="sidebar">
        <div className="sidebar-logo">🔍 Skemly</div>
        <nav>
          <a href="#" className="active">Dashboard</a>
          {/* <a href="#">Analyze URL</a>
          <a href="#">Settings</a>
          <a href="#">Help</a> */}
        </nav>
      </aside>

      <div className="content-wrapper">
        {/* <header>
          <div className="header-title">Schema Analyzer</div>
          <div className="profile-icon">👤</div>
        </header> */}

        <main>
          <div className="card input-section">
            <h2>Analyze URL</h2>
            <label htmlFor="url-input">Enter URL</label>
            <input
              type="text"
              id="url-input"
              placeholder="https://example.com"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
            <button onClick={handleAnalyze} disabled={loading}>
              {loading ? 'Analyzing...' : 'Analyze'}
            </button>
          </div>

          {loading && <div className="loading">Loading...</div>}

          <div className="card results-section">
            <div className="result-box">
              <h2>Existing Schema Markup</h2>
              <pre>{existingSchema ? JSON.stringify(existingSchema, null, 2) : 'No data'}</pre>
            </div>
            <div className="result-box">
              <h2>Suggested Schema Enhancements</h2>
              <pre>{suggestedSchema ? JSON.stringify(suggestedSchema, null, 2) : 'No data'}</pre>
              {suggestedSchema && (
                <button onClick={handleCopy} className="copy-button">Copy to Clipboard</button>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;